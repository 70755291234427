.loading-container-contained {
  position: absolute;
  inset: 0;
  background-color: var(--color-fourth-transparent);
  display: grid !important;
  place-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
}
.loading-container-contained-less-transparent {
  background-color: var(--zoom-bg-color);
}
.loading-container-contained > div {
  display: flex;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container .loading-part {
  width: 15px;
  height: 15px;
  padding: 2px;
  border-radius: 6px;
  animation: loadingAnimation 1.6s ease-in-out forwards infinite;
}

.loading-part-first {
  animation-delay: 0s !important;
}
.loading-part-second {
  margin-left: 6px;
  margin-right: 6px;
  animation-delay: 0.2s !important;
}
.loading-part-third {
  animation-delay: 0.4s !important;
}
.loading-part-white {
  background-color: var(--color-third);
}
.loading-part-classic {
  background-color: var(--color-fourth-darker);
}

@keyframes loadingAnimation {
  from {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(-12px);
  }
  28% {
    transform: translateY(0px);
  }
  to {
    transform: translateY(0px);
  }
}
