a {
  text-decoration: none;
  color: var(--cl-main-intensive-1-more);
  transition: 0.1s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

.no-style-link {
  color: inherit;
  text-decoration: inherit !important;
}
