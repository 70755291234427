body,
html {
  line-height: 1.7rem;
}

.section-title {
  color: var(--cl-main-dark);
  text-align: center;
  margin-top: 4rem;
}

.article-stats-example {
  text-align: center;
  /* color: var(--color-fourth-darker); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 3rem;
  place-items: center;
  text-wrap: balance;
}

@media screen and (max-width: 768px) {
  .article-stats-example {
    grid-template-columns: 1fr;
  }
}

.stats-example-container {
  height: min-content;
}

.stats-example-container > img {
  width: 100%;
  max-width: 800px;
}

.last-message-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.last-message-wrapper > a {
  margin-top: 1rem;
  display: grid;
}

.info-to-clients-container {
  position: relative;
}

.info-to-clients-container > iframe {
  border: none;
}
.articles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 3rem;
  justify-content: center;
}
.third-part-container {
  background-color: var(--color-third);
}
