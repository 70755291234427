button,
.button-transparent-stylish-old {
  margin: 3px;
  padding: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition:
    all 0.1s ease-in-out,
    opacity 0.3s ease-in-out;
  opacity: 1;
  color: var(--cl-main-dark);
}

button[data-version="classic"],
button[data-version="alternative"],
button[data-version="transparent"],
button[data-version="transparent-stylish"],
button[data-version="transparent-stylish-old"],
button[data-version="filled-tonal"],
button[data-version="status-container"],
.button-transparent-stylish-old {
  opacity: 1;
  border-radius: 0.6rem;
}
button[data-version="classic"]:active,
button[data-version="alternative"]:active,
button[data-version="transparent"]:active,
button[data-version="transparent-stylish"]:active,
button[data-version="transparent-stylish-old"]:active,
button[data-version="filled-tonal"]:active,
button[data-version="status-container"]:active,
.button-transparent-stylish-old {
  opacity: 0.5;
  transition: opacity 0s ease-in-out;
}

button[data-version="classic"] {
  background-color: var(--cl-main-intensive-1);
  color: var(--color-third);
  border: 2px solid var(--cl-main-intensive-1);
}
button[data-version="classic"]:hover {
  background-color: var(--color-fourth-darker);
}

button[data-version="alternative"] {
  background-color: var(--color-third);
  color: var(--cl-main-intensive-1);
  border: 0.1rem solid var(--cl-main-disabled-transparent-2);
}
button[data-version="alternative"]:hover {
  background-color: var(--color-fourth-transparent);
}

button[data-version="filled-tonal"] {
  background-color: var(--cl-main-light-2-transparent);
  color: var(--cl-main-intensive-1);
  border: 0.15rem solid transparent;
}
button[data-version="filled-tonal"]:hover {
  color: var(--cl-main-intensive-1);
  background-color: var(--cl-main-light-2);
}
button[data-version="filled-tonal"][data-active="true"] {
  border: 0.1rem solid var(--cl-main-intensive-2) !important;
}
button[data-version="transparent"] {
  background-color: transparent;
}
button[data-version="transparent"]:hover {
  background-color: var(--color-fourth);
}

button[data-version="transparent-stylish"] {
  background-color: transparent;
  transition: none;
  filter: brightness(0) saturate(100%) invert(42%) sepia(6%) saturate(461%)
    hue-rotate(201deg) brightness(87%) contrast(88%);
}
button[data-version="transparent-stylish"]:hover {
  filter: invert(29%) sepia(82%) saturate(5372%) hue-rotate(238deg)
    brightness(98%) contrast(94%);
}

button[data-version="transparent-stylish-old"] {
  background-color: transparent;
  color: var(--cl-main-intensive-1);
  border: 0.15rem solid transparent;
}
button[data-version="transparent-stylish-old"]:hover,
.button-transparent-stylish-old:hover {
  background-color: var(--color-fourth-transparent);
}
button[data-version="transparent-stylish-old"][data-active="true"] {
  border: 0.15rem solid var(--cl-main-intensive-2);
  background-color: var(--cl-main-light-2-transparent);
}

button[data-version="status-container"] {
  font-size: 1em;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

button[data-version="status-container"]:hover {
  background-color: var(--color-fourth-transparent);
}

.button-disabled-extra-spacing {
  margin: 0;
}
