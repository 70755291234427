:root {
  --animation-time: 0.4s;
  --navbar-size: 65px;
  --clickable-font-size: 22px;
}

body,
html {
  margin: 0;
  padding: 0;
  /* background-color: #c0c1ee; */
  color: var(--color-sixth);
  font-size: 17px;
  font-family: "Source Sans pro";
  font-display: optional;
}

.landing-page-main {
  min-height: calc(100vh - var(--navbar-size));
  background-image: url("./assets/images/background.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-color: rgba(242, 243, 255, 0.431);
  background-blend-mode: soft-light;
  background-attachment: fixed;
}

nav {
  height: var(--navbar-size);
  width: 100%;
  /* box-shadow: 0px 0px 5px 0px black; */
  position: fixed;
  z-index: 3;
  background-color: var(--color-third-transparent-2);
  backdrop-filter: blur(10px);
  transition: height 0.2s ease-in-out;
}
.navbar-container-bigger {
  height: calc(var(--navbar-size) + 16px);
}
.counter-letter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  animation: counterAnimation 2s ease-in-out forwards;
}
.counter-letter span {
  height: 2.4em;
  padding: 0;
  margin: 0;
}
.counter-container {
  padding: 10px;
  font-weight: 500;
  display: flex;
  width: fit-content;
  height: 1em;
  overflow: hidden;
}
@keyframes counterAnimation {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
    transform: translateY(calc(-100% + 2.4em));
  }
}

.first_look-container {
  min-height: calc(100vh - var(--navbar-size));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: clamp(1.7rem, 1.6vw + 1rem, 3.2rem);
  padding: 2rem;
  /* padding-left: 5rem; */
  position: relative;

  color: var(--cl-main-dark);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  text-align: center;
  text-wrap: balance;
}
.first-look-text {
  z-index: 2;
  display: grid;
  width: 100%;
}
.first-look-text > p {
  font-size: 1.2rem;
  text-wrap: balance;
  margin-top: 0;
}
.login-to-gallery-button {
  border: none !important;
  font-size: 0.44em;
  color: var(--color-fourth-darker) !important;
}
.photographer-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.first-look-text .titles {
  transition: 0.2s ease-in-out;
  opacity: 0.8;
  transform: scale(1);
  cursor: default;
}
@media only screen and (max-width: 425px) {
  .titles {
    font-size: 0.8em;
  }
}
.first-look-text .titles:hover,
.first-look-text .titles:focus {
  transform: scale(1.1);
  opacity: 1;
}
.info-to-clients-container {
  font-size: 1.2em;
}
.info-to-clients-container h6 {
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-weight: bolder;
  font-size: 1em;
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: visible;
  overflow-x: auto;
  border-bottom: 0.1rem solid var(--color-fourth-transparent);
}
.navigation-container {
  overflow-y: visible;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.navigation-container > a {
  width: max-content;
}
.right_nav_side-container {
  height: 100%;
  display: flex;
  width: max-content;
}
nav a {
  position: relative;
}
nav .current-site::after,
nav a:focus::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-fourth);
  height: 6px;
  transform: translateY(6px);
  border-radius: 8px;
}

nav a {
  font-size: 1.1rem;
  text-decoration: none;
  display: inline-block;
  padding: 7px;

  color: var(--cl-main-intensive-1-more);
  transition: 0.1s ease-in-out;
  border-radius: var(--b-radius);
}
nav a:hover {
  color: var(--cl-main-intensive-1-more);
  text-decoration: none;
  background-color: var(--color-fourth-transparent);
}

nav .current-site:hover {
  transform: translateY(0px);
}
.account_actions-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;

  margin-left: 5px;
  margin-right: 5px;
}
.account_actions-container > button {
  width: max-content;
}
button {
  margin: 3px;
  padding: 8px;
  border-radius: var(--b-radius);
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  transition:
    border-radius 0.1s ease-in-out,
    background-color 0.1s ease-in-out,
    opacity 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
}

button[data-version="classic"] {
  background-color: var(--color-fourth);
  color: var(--color-third);
  border: 2px solid var(--color-fourth);
}
button[data-version="classic"]:hover {
  border-radius: 8px;
  background-color: var(--color-fourth-darker);
}

button[data-version="filled-tonal"] {
  background-color: var(--color-fourth-transparent) !important;
}
button[data-version="filled-tonal"]:hover {
  box-shadow: 0 0 0.2rem 0 var(--color-fourth-darker);
}
button[data-version="alternative"] {
  background-color: var(--color-third);
  border: 2px solid var(--color-fourth);
}
button[data-version="alternative"]:hover {
  border-radius: 8px;
}
button:active {
  opacity: 0.7;
  transition:
    border-radius 0.1s ease-in-out,
    background-color 0.1s ease-in-out,
    opacity 0s ease-in-out;
}
.main-icon {
  margin-right: 5px;
  margin-left: 5px;
}
.second_look-container {
  padding-bottom: 100px;
  row-gap: 2rem;
  padding-top: 0.1rem;
  z-index: 1;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--color-third);
  color: var(--cl-main-dark);
  animation: appear linear;
  animation-timeline: view();
  animation-range-start: 0;
  animation-range-end: 600px;
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-140px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.text-primary-color {
  color: var(--cl-main-intensive-1-more);
}
.second_look-title {
  grid-column: line2;
  margin-bottom: 0;
  height: 1.5em;
}
.reason {
  grid-column: line2;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.4s ease-in-out;

  width: 230px;
  height: 230px;
  padding: 1rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--color-fourth-transparent);
  background-color: var(--color-third);
  text-wrap: balance;
}
.article-visible {
  opacity: 1;
  transform: translateX(0px);
}
footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  position: relative;
  z-index: 1;
  background-color: var(--color-third);
}
.footer-container {
  border-top: 1px solid var(--color-fourth-transparent);
  min-height: 100px;
  width: 80%;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 2.2em;
  justify-content: space-around;
}
.footer-bar {
  display: flex;
  flex-direction: column;
}
.login-container {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  animation: showForm 0.2s ease-in-out forwards;

  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  padding: 3rem;
  background-color: var(--color-third);
  animation: showFormBox 0.2s ease-in-out forwards;
  border-radius: var(--b-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.login-box form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
}
form label {
  display: flex;
  flex-direction: column;
}

@keyframes showForm {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: var(--color-seventh);
  }
}
@keyframes showFormBox {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

input {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 8px;
  font-size: 18px;
  border: 1px solid var(--color-fourth);
  border-radius: var(--b-radius);
  transition: 0.1s ease-in-out;
}
input:hover {
  box-shadow: 0px 0px 3px 0px grey;
}
input:focus {
  outline: none;
  box-shadow: 0px 0px 1px 2px var(--color-fourth);
}

.create_account-container {
  min-width: fit-content;
  width: 400px;
  max-width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  border: 4px solid var(--color-fourth-transparent);
  background-color: var(--color-third);
  border-radius: 6px;
}
.create_account-main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.required {
  color: red;
}

.checkbox {
  border: 2px solid var(--color-fourth);
  width: 17px;
  height: 17px;
  margin: 4px;
  padding: 3px;
  cursor: pointer;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}
.box-checked {
  background-color: var(--color-fourth-darker);
}
.checkbox:hover {
  border-radius: 5px;
}
.checkbox div {
  color: white;
  transition: 0.2s ease-in-out;
  transform: scale(0.4);
  opacity: 0;
}
.checkbox-checked {
  transform: scale(1) !important;
  opacity: 1 !important;
}
.flex-container-default {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.flex-container-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create_account-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input[data-isincorrect="true"] {
  border: 2px solid red;
}
.form-error {
  width: 230px;
}

@keyframes loadingAnimation-tiny {
  from {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(-8px);
  }
  28% {
    transform: translateY(0px);
  }
  to {
    transform: translateY(0px);
  }
}

.info-correct {
  color: var(--color-second);
}
