.login-to-gallery-button-info {
  text-align: center;
}

.login-to-gallery-wrapper {
  display: grid;
}

.login-to-gallery-wrapper a {
  display: grid;
}
