.main-icon-container {
  display: flex;
  align-items: center;
}

.landing-page-nav-container {
  width: initial;
  right: 0;
  left: 0;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
