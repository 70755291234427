:root {
  --color-first-lighter: #9696ef;
  --color-first: #5f5fed;
  --color-first-dark: #4848c5;
  --color-second: #5757e934;
  --color-background-transparent: #5757e934;

  --color-third: white;
  --color-third-trasparent: #f0f0f08b;
  --color-third-transparent-2: rgba(231, 231, 238, 0.708);

  --color-fourth: #6969eb;
  --color-fourth-transparent: #ababab3d;
  --color-fourth-transparent-2: rgb(244, 244, 244);
  --color-fourth-darker: #5656ff;
  --color-fifth: #8a9dd1;
  --color-sixth: #10102f;
  --color-seventh: #0000007c;

  --color-second-lighter: #5656ff;

  --cl-main-intensive-1-more: #3e3ee4;

  --cl-main-intensive-1: #4b4bf3;
  --cl-main-intensive-2: #5b5bf1;
  --cl-main-less-intensive: #7777f1;
  --cl-main-disabled: #7c7c88;
  --cl-main-disabled-transparent-1: #84849497;
  --cl-main-disabled-transparent-2: #8484944f;
  --cl-main-light-1: #9d9dfb;
  --cl-main-light-2: #dedeff;
  --cl-main-light-2-transparent: #dedeff89;
  --cl-main-dark: hsl(238, 100%, 15%);
  --cl-main-lighter: hsl(238, 100%, 28%);
  --color-fourth-transparent-accent: #5757e934;
  --color-disabled-transparent: #c7c7c7;
  --b-radius: 0.6rem;

  /* Functional colors */
  --cl-complementary: hsl(49, 100%, 64%);
  --cl-complementary-transparent: hsla(49, 100%, 64%, 0.153);
}
